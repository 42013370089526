<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div v-if="customers.length" class="container-pages">
					<b-pagination
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<div
					v-if="possuiPermissao('GER_I_CUSTOMER')"
					class="container-add-new"
					v-bind:class="!customers.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="customers.length" class="head-row mb-4">
				<span class="text-head-row ml-1">
					Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + customers.length }} de {{ pagination.count }}
				</span>
				<b-form
					class="text-head-row width-pagination-items">
					<label class="mr-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-1 mb-2 width-pagination"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="lg"
			>
				<div class="modal-edicao mb-5 modal-edicao-label">
					<b-row class="mt-3 filters-orientation">
						<b-col class="text-modal">
							<span class="label-modal">CPF/CNPJ</span>
							<b-form-input
								name="identityDocument"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.identityDocument"
								autocomplete="off"
								placeholder="Digite o CPF/CNPJ"
								v-validate="'validateCPF'"
								:state="validateState('identityDocument')"
								@blur="formatDocumentSelected()"
							/>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Nome</span>
							<b-form-input
								name="name"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.name"
								autocomplete="off"
								placeholder="Digite o seu nome"
								v-validate="{ required: true }"
								:state="validateState('name')"
							/>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Sobrenome</span>
							<b-form-input
								name="surname"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.surname"
								autocomplete="off"
								placeholder="Digite o seu sobrenome"
								v-validate="{ required: false }"
								:state="validateState('surname')"
							/>
						</b-col>
					</b-row>
					<b-row class="mt-3 filters-orientation">
						<b-col class="text-modal">
							<span class="label-modal">CEP</span>
							<b-form-input
								name="zipCode"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.zipCode"
								autocomplete="off"
								placeholder="Digite o CEP"
								v-validate="{ required: false }"
								:state="validateState('zipCode')"
							/>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Endereço</span>
							<b-form-input
								name="address"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.address"
								autocomplete="off"
								placeholder="Digite o endereço"
								v-validate="{ required: false }"
								:state="validateState('address')"
							/>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Bairro</span>
							<b-form-input
								name="neighborhood"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.neighborhood"
								autocomplete="off"
								placeholder="Digite o bairro"
								v-validate="{ required: false }"
								:state="validateState('neighborhood')"
							/>
						</b-col>
					</b-row>
					<b-row class="mt-3 filters-orientation">
						<b-col class="text-modal">
							<span class="label-modal">Cidade</span>
							<b-form-input
								name="city"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.city"
								autocomplete="off"
								placeholder="Digite a cidade"
								v-validate="{ required: false }"
								:state="validateState('city')"
							/>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Estado</span>
							<b-form-input
								name="state"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.state"
								autocomplete="off"
								placeholder="Digite o estado"
								v-validate="{ required: false }"
								:state="validateState('state')"
							/>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Pais</span>
							<b-form-input
								name="country"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.country"
								autocomplete="off"
								placeholder="Digite o pais"
								v-validate="{ required: false }"
								:state="validateState('country')"
							/>
						</b-col>
					</b-row>
					<b-row class="mt-3 filters-orientation">
						<b-col class="text-modal">
							<span class="label-modal">E-mail</span>
							<b-form-input
								name="email"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.email"
								autocomplete="off"
								placeholder="Digite o seu e-mail"
								v-validate="{ required: false }"
								:state="validateState('email')"
							/>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Celular</span>
							<b-form-input
								name="cellPhone"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.cellPhone"
								autocomplete="off"
								placeholder="Digite número de celular"
								v-validate="{ required: false }"
								:state="validateState('cellPhone')"
							/>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Telefone</span>
							<b-form-input
								name="telephone"
								class="modal-edicao-input"
								type="text"
								v-model="clienteSelecionado.telephone"
								autocomplete="off"
								placeholder="Digite número de telefone"
								v-validate="{ required: false }"
								:state="validateState('telephone')"
							/>
						</b-col>
					</b-row>
				</div>
				<b-button class="ml-1 mt-5 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-5 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-5 mr-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="customers.length" class="table-responsive">
			<DataTable
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="clientes"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_CUSTOMER')"
				:permissaoDelete="possuiPermissao('GER_D_CUSTOMER')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { CustomerService } from "../../services/customer";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import { formatIdentityDocument } from "@/helpers/validateIndentity";

	export default {
		components: {
			TemplateBase,
			DataTable
		},

		inject: ["parentValidator"],

		data () {
			return {
				customers: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				clienteSelecionado: {
					name: "",
					surname: "",
					identityDocument: "",
					address: "",
					neighborhood: "",
					zipCode: "",
					city: "",
					state: "",
					country: "",
					cellPhone: "",
					telephone: "",
					email: ""
				},

				customerService: new CustomerService(),

				errMsg: "Nenhum cliente cadastrado!",
				colunas: [
					"CPF/CNPJ",
					"Nome",
					"Sobrenome",
					"E-mail",
					"Celular",
					"Telefone"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				invalidForm: true,
				noEditCustomer: false,
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: -1
				},
				formatIdentityDocument
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.findClientes();
		},

		methods: {
			sortTableBy (v) {
				this.sortTable = v;
				this.findClientes();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findClientes();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findClientes();
			},

			async findClientes () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;
				const result = await this.customerService.listCustomer({ offset, limit, sortTable }) || {};
				this.customers = result.rows || [];

				this.linhas = result.rows.reduce((acc, customer) => [
					...acc,
					{
						idCustomer: customer.idCustomer,
						cols: [
							customer.identityDocument,
							customer.name,
							customer.surname,
							customer.email,
							customer.cellPhone,
							customer.telephone
						]
					}
				], []);
				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			mostrarEdicao (item_) {
				this.invalidForm = true;
				let item = {};
				if (item_.idCustomer)
					[ item ] = this.customers.filter((customer) => customer.idCustomer === item_.idCustomer);

				const {
					idCustomer,
					name,
					surname,
					identityDocument,
					address,
					neighborhood,
					zipCode,
					city,
					state,
					country,
					cellPhone,
					telephone,
					email
				} = item;

				if (!idCustomer) {
					this.titleModalEdicao = "Cadastrar Novo Cliente";
					this.noEditCustomer = true;
				} else {
					this.titleModalEdicao = "Editar Cliente";
					this.noEditCustomer = false;
				}

				this.clienteSelecionado.idCustomer = idCustomer;
				this.clienteSelecionado.name = name;
				this.clienteSelecionado.surname = surname;
				this.clienteSelecionado.identityDocument = this.formatIdentityDocument(identityDocument);
				this.clienteSelecionado.address = address;
				this.clienteSelecionado.neighborhood = neighborhood;
				this.clienteSelecionado.zipCode = zipCode;
				this.clienteSelecionado.city = city;
				this.clienteSelecionado.state = state;
				this.clienteSelecionado.country = country;
				this.clienteSelecionado.cellPhone = cellPhone;
				this.clienteSelecionado.telephone = telephone;
				this.clienteSelecionado.email = email;
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const [ item ] = this.customers.filter((customer) => customer.idCustomer === item_.idCustomer);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o cliente?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idCustomer);
			},

			async salvarEdicao () {
				const invalidFields = await this.validateModal();
				if (invalidFields) {
					await this.$swal({
						title: "Formulário Inválido",
						text: "Existe informação sem preencher!",
						type: "error",
						showCancelButton: false,
						confirmButtonColor: "#DD6B55",
						confirmButtonText: "OK",
						reverseButtons: true
					});
					return;
				}

				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					name: this.clienteSelecionado.name || "",
					surname: this.clienteSelecionado.surname || "",
					identityDocument: this.clienteSelecionado.identityDocument || "",
					address: this.clienteSelecionado.address || "",
					neighborhood: this.clienteSelecionado.neighborhood || "",
					zipCode: this.clienteSelecionado.zipCode || "",
					city: this.clienteSelecionado.city || "",
					state: this.clienteSelecionado.state || "",
					country: this.clienteSelecionado.country || "",
					cellPhone: this.clienteSelecionado.cellPhone || "",
					telephone: this.clienteSelecionado.telephone || "",
					email: this.clienteSelecionado.email || ""
				};
				if (this.clienteSelecionado.idCustomer)
					payload.idCustomer = this.clienteSelecionado.idCustomer;

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.customerService.upsertCustomer({payload});

						this.fecharModal("modalEdicao");
						this.findClientes();

						return {
							title: "Sucesso!",
							body: "Cliente salvo com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar cliente!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
				this.clienteSelecionado.idCustomer = "";
			},

			async apagar (idCustomer) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.customerService.deleteCustomer({ idCustomer });
						this.findClientes();

						return {
							title: "Sucesso!",
							body: "Usuário apagado com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar o cliente!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			limparModal () {
				this.clienteSelecionado.name = "";
				this.clienteSelecionado.surname = "";
				this.clienteSelecionado.identityDocument = "";
				this.clienteSelecionado.address = "";
				this.clienteSelecionado.neighborhood = "";
				this.clienteSelecionado.zipCode = "";
				this.clienteSelecionado.city = "";
				this.clienteSelecionado.state = "";
				this.clienteSelecionado.country = "";
				this.clienteSelecionado.cellPhone = "";
				this.clienteSelecionado.telephone = "";
				this.clienteSelecionado.email = "";
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async validateModal () {
				const validationFields = Object.keys(this.veeFields);
				if (!validationFields.length)
					return true;

				const validations = validationFields.reduce((acc, ref) => {
					if (this.veeFields[ref] && this.veeFields[ref].invalid)
						return [...acc, this.veeFields[ref].invalid];

					return acc;
				}, []);

				const invalidFields = validations.filter(field => field);
				return Boolean(invalidFields && invalidFields.length);
			},

			formatDocumentSelected () {
				this.clienteSelecionado.identityDocument = formatIdentityDocument(this.clienteSelecionado.identityDocument);
			}
		}
	};
</script>

<style scoped>
	.text-modal {
		font-family: "Roboto Condensed Regular";
		letter-spacing: -0.05em;
		font-size: 20px;
	}

	.label-modal {
		color: #696969 !important;
	}

	.table-th-funcionalidade {
		font-family: "Roboto Condensed Medium";
		letter-spacing: -0.05em;
		font-size: 20px;
	}

	.table-td-funcionalidade {
		font-family: "Roboto Condensed Regular";
		letter-spacing: -0.05em;
		font-size: 16px;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.container-add-new {
		display: flex;
		width: 30%;
		height: 3rem;
		justify-content: right;
	}

	.add-button {
		width: 135;
		height: 43;
		font-family: "Roboto Condensed Medium";
		letter-spacing: -0.05em;
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: -16px;
	}

	.text-head-row {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: "Roboto Condensed Bold";
		letter-spacing: -0.05em;
		font-size: 14px;
		color: #6C757D;
	}

	.modal-edicao-label {
		font-family: "Roboto Condensed Regular";
		font-size: 23px;
		letter-spacing: -0.05em;
	}

	.modal-edicao-input {
		font-family: "Roboto Condensed Regular";
		font-size: 20px;
		letter-spacing: -0.05em;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}
</style>

<style>
	.form-control {
		height: auto !important;
	}
</style>
